import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// import PropTypes from 'prop-types';
import { useUserIPGrabber } from '@cleverrealestate/clever-helpers';
import Autohunter from '@cleverrealestate/clever-components-v2/dist/components/Forms/Autohunter';
import SEO from '../components/Seo';
import '../css/autohunter.css';

const AutohunterPage = () => {
  useUserIPGrabber();
  const data = useStaticQuery(graphql`
     query {
      allCosmicjsAutohunterV2(filter: {slug: {eq: "autohunter-v2-copy-configs"}}) {
        edges {
          node {
            slug
            metadata {
              buyers_commission
              commission_model
              great_service
              local_buyer
              intro
              more_questions
              next_steps
              personal_match
              non_ccb_buying_check {
                bottom_content
                mid_content
                top_content
              }
              ccb_buying_check {
                bottom_content
                top_content
              }
              agent_image {
                imgix_url
              }
              exit_page {
                authenticated_customer
                non_authenticated_customer
              }
              buyer_exit {
                authenticated_customer
                non_authenticated_customer
              }
            }
          }
        }
      }
    }
  `);

  return (
    <main>
      <SEO
        pathname="/get-connected-now/"
        title="Get Connected"
        meta={[{ name: 'robots', content: 'noindex' }]}
      />
      <Autohunter pageContext={data.allCosmicjsAutohunterV2.edges[0].node.metadata} />
    </main>
  );
};

AutohunterPage.propTypes = {};

AutohunterPage.defaultProps = {};

export default AutohunterPage;
